import Vue from 'vue';
import { HTTP, baseUploadURL, baseURL, socket } from './axios';
import moment from 'moment';

Vue.prototype.$baseUploadURL = baseUploadURL;

Vue.prototype.$socket = socket;
Vue.prototype.$http = HTTP;
Vue.prototype.$moment = moment;

Vue.prototype.$file2base64 = (
  ev: any,
  cb: (arg0: string | ArrayBuffer | null) => void,
) => {
  const file = ev.target.files[0];
  const reader = new FileReader();
  reader.onload = (e: ProgressEvent) => {
    const content = (e.target as FileReader).result;
    cb(content);
  };
  reader.readAsDataURL(file);
};


Vue.prototype.$file2upload = ( ev: any, cb: (arg0: string | ArrayBuffer | null) => void,)=>{
  const file = ev.target.files[0];
  cb(file);
}

Vue.prototype.$toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

Vue.prototype.$getStatus = (val: string) => {
  const list = {
    UnderDistribution: 'تحت التوزيع',
    UnderOperation: 'تحت التشغيل',
  };
  return list[val];
};

Vue.prototype.$errorHandler = (event: any) => {
  event.target.src = '/images/def.svg';
};

Vue.prototype.$checkRoles = (name: any) => {
  const user = JSON.parse(localStorage.adminKB);
  const roles = user.roles ?? JSON.parse(user.roles);
  if (roles.includes(name)) {
    return true;
  }
  return false;
};

Vue.prototype.$datePadding = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('DDMMYY');
  } else {
    return '';
  }
};

Vue.prototype.$byYear = (value) => {
  if (value) {
    const duration = moment(String(value), 'MM-YYYY');

    return duration.format('YYYY');
  } else {
    return '';
  }
};
Vue.prototype.$codePadding = (text, length = 6) => {
  return String(text).padStart(length, '0');
};

Vue.prototype.$durationFormat = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('DD-MM-YYYY');
  } else {
    return '';
  }
};


Vue.prototype.$getByLang = (text: any) => {
  if (text && JSON.parse(text)) {
    return JSON.parse(text)['de'];
  } else {
    return "";
  }
};

Vue.prototype.$durationFormat2 = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('YYYY-MM-DD');
  } else {
    return '';
  }
};
Vue.prototype.$durationFormatFullDate = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('DD-MM-YYYY hh:mm A');
  } else {
    return '';
  }
};





Vue.prototype.$durationFormatCraetedAt = (value,t) => {
  if (value) {
    const duration = moment(String(value));
    if (t == 's') {
      
      return duration.format('YYYY-MM-DD');
    }else{
      return duration.format(':mm:ss');

    }
  } else {
    return '';
  }
};




Vue.prototype.$durationFormatFull = (value, v2) => {
  if (value && v2) {
    const duration = moment(String(value));
    const duration2 = moment(String(v2));

    return duration.format('hh:mm A') + ' bis ' + duration2.format('hh:mm A');
  } else {
    return '';
  }
};

Vue.prototype.$durationFormatFull24 = (value, v2) => {
  if (value && v2) {
    const duration = moment(String(value));
    const duration2 = moment(String(v2));

    return duration.format('H:mm') + ' bis ' + duration2.format('H:mm');
  } else {
    return '';
  }
};
Vue.prototype.$durationFormatFull2 = (value, v2) => {
  if (value && v2) {
    const duration = moment(String(value));
    const duration2 = moment(String(v2));

    return (
      duration.format('H:mm') + ' bis ' + duration2.format('H:mm') + ' Uhr'
    );
  } else {
    return '';
  }
};


Vue.prototype.$durationFormatFull3 = (value, v2) => {
  if (value && v2) {
    const duration = moment(String(value)).add(1, 'hours');
    const duration2 = moment(String(v2)).add(1, 'hours');

    return (
      duration.format('H:mm') + ' bis ' + duration2.format('H:mm') + ' Uhr'
    );
  } else {
    return '';
  }
};


Vue.prototype.$timeByHours = (hours, minutes) => {
  let m = '00';

  if (minutes > 0) {
    m = String(minutes).padStart(2, '0');
  }
  if (minutes < 0) {
    m = String(minutes * -1).padStart(2, '0');
  }

  return `${hours}:${minutes > 0 ? m : '00'}`;
};

Vue.prototype.$durationTimeFormat = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('HH:mm');
  } else {
    return '';
  }
};

Vue.prototype.$eventHub = new Vue();

Vue.filter('stripHTML', function (value) {
  const div = document.createElement('div');
  div.innerHTML = value;
  const text = div.textContent || div.innerText || '';
  return text;
});

Vue.prototype.$htmlPrint = (stylesHtml, prtHtml) => {
  prtHtml = prtHtml.replace(
    `<div class="table-header">`,
    `<div class="table-header text-center"><img src="https://kleopatramassage-beauty.com/image/logo.png" style="height: 50px;" />`,
  );

  return `<!DOCTYPE html>
  <html>
    <head>
    ${stylesHtml}
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
  <style>

  
   .p-sortable-column-icon , .p-filter-column{
   display: none;
   }
   .pi-chevron-right::before {
      content: "\e901";
      display: none;
  }
  .p-datatable .p-paginator-bottom {
      display: none;
  }
  .p-datatable .p-datatable-header {
      background: #495057;
      border: 1px solid #e9ecef;
          border-top-width: 1px;
          border-right-width: 1px;
          border-bottom-width: 1px;
          border-left-width: 1px;
      border-width: 1px 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 600;
      color: #f8f9fa;
  }
  .yes-print {
    display: none;
  }
  
  @media print {
    .yes-print {
      display: block !important;
    }
    .no-print {
      display: none !important;
    }

    .p-datatable table {
      border-collapse: collapse !important;
      width: 100% !important;
      table-layout: fixed !important;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
      padding: 0.5rem 0.5rem !important;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
      border-width: 1px 1px 1px 1px !important;
    }
    .p-datatable .p-datatable-thead > tr > th {
      text-align: center !important !important;
    }
    .p-datatable .p-datatable-thead > tr > th {
      text-align: left !important;
      padding: 1rem 1rem !important;
      border: 1px solid #e9ecef !important;
       
      border-width: 0 0 1px 0 !important;
      font-weight: 600 !important;
      color: #495057 !important;
      background: #f8f9fa !important;
      transition: box-shadow 0.2s !important;
    }
    .p-datatable .p-datatable-tbody > tr {
      background: #ffffff !important;
      color: #495057 !important;
      transition: box-shadow 0.2s !important;
      outline-color: #a6d5fa !important;
    }


              
          .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {

              padding: 0.5rem 0.5rem !important;

          }
          .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {

              border-width: 1px !important;

          }
          .p-datatable .p-datatable-tbody > tr > td {

              text-align: center !important;

          }
          .p-datatable .p-datatable-tbody > tr > td {

              text-align: left !important;
              border: 1px solid #e9ecef !important;
              border-width: 0 0 1px 0 !important;
              padding: 1rem 1rem !important;

          }
          .p-datatable .p-datatable-tbody > tr > td {
            text-align: center !important;
          }
  }
   </style>
   </head>
    <body>
    
    
    <div class="card">
      ${prtHtml}
      </div>
         
      
     
      
    </body>
  </html>`;
};

Vue.prototype.$htmlPrint2 = (stylesHtml, prtHtml, title) => {
  prtHtml = prtHtml.replace(
    `<div class="table-header">`,
    `<div class="table-header text-center">${title}`,
  );

  return `<!DOCTYPE html>
  <html>
    <head>
    ${stylesHtml}
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
  <style>

  
  .layout-main ,.card {
    
    padding: 0;
  }


   .p-sortable-column-icon , .p-filter-column{
   display: none;
   }
   .pi-chevron-right::before {
      content: "\e901";
      display: none;
  }
  .p-datatable .p-paginator-bottom {
      display: none;
  }
  .p-datatable .p-datatable-header {
      background: #495057;
      border: 1px solid #e9ecef;
       
      border-width: 1px 0 1px 0;
      padding: 0.2rem 0.2rem;
      font-weight: 600;
      color: #f8f9fa;
  }
  .yes-print {
    display: none;
  }
  
  @media print {
    .yes-print {
      display: block !important;
    }
    .no-print {
      display: none !important;
    }

    .p-datatable table {
      border-collapse: collapse !important;
      width: 100% !important;
      table-layout: fixed !important;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
      padding: 0.1rem 0.1rem !important;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
      border-width: 1px 1px 1px 1px !important;
    }
    .p-datatable .p-datatable-thead > tr > th {
      text-align: center !important !important;
    }
    .p-datatable .p-datatable-thead > tr > th {
      text-align: left !important;
      padding: 0.2rem 0.2rem !important;
      border: 1px solid #e9ecef !important;
       
      border-width: 0 0 1px 0 !important;
      font-weight: 600 !important;
      color: #495057 !important;
      background: #f8f9fa !important;
      transition: box-shadow 0.2s !important;
    }
    .p-datatable .p-datatable-tbody > tr {
      background: #ffffff !important;
      color: #495057 !important;
      transition: box-shadow 0.2s !important;
      outline-color: #a6d5fa !important;
    }


              
          .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {

              padding: 0.2rem 0.2rem !important;

          }
          .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {

              border-width: 1px !important;

          }
          .p-datatable .p-datatable-tbody > tr > td {

              text-align: center !important;

          }
          .p-datatable .p-datatable-tbody > tr > td {

              text-align: left !important;
              border: 1px solid #e9ecef !important;
              border-width: 0 0 1px 0 !important;
              padding: 0.2rem 0.2rem !important;

          }

          .p-datatable .p-datatable-tbody > tr > td {
            text-align: center !important;
          }


          .p-datatable .p-datatable-thead > tr > th {
            text-align: center !important;
          }

          .p-datatable.p-datatable-sm .p-datatable-thead > tr > th:first-child{
            width:15% !important;
          }

  }
   </style>
   </head>
    <body>
    <div class="layout-main">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card">
          <div class="p-datatable-customers table table-striped p-datatable p-component p-datatable-hoverable-rows">
      ${prtHtml}
          </div>
        </div>
      </div>
    </div>
  </div>
    </body>
  </html>`;
};
