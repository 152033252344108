import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";

// Install BootstrapVue
Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Install VueTelInput
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput);

// If you don't need the styles, do not connect
import VueSweetalert2 from 'vue-sweetalert2';

// import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);


// Install PrimeVue
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
Vue.use(PrimeVue, {
    ripple: true,
    locale: {
        firstDayOfWeek: 1, // Monday
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    }
});
Vue.use(ToastService);
Vue.use(ConfirmationService);
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

// Install Component
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import SplitButton from "primevue/splitbutton";
import Fieldset from "primevue/fieldset";
import PickList from "primevue/picklist";
import InputSwitch from "primevue/inputswitch";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Dialog from "primevue/dialog";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import Editor from "primevue/editor";
import InputNumber from "primevue/inputnumber";
import OrderList from "primevue/orderlist";
import Chip from "primevue/chip";
import InputText from "primevue/inputtext";
import BadgeDirective from "primevue/badgedirective";
import TieredMenu from "primevue/tieredmenu";
import OverlayPanel from "primevue/overlaypanel";
import Calendar from "primevue/calendar";
import SelectButton from "primevue/selectbutton";
import ColorPicker from "primevue/colorpicker";
import TreeTable from "primevue/treetable";
import Knob from "primevue/knob";
import Card from "primevue/card";
import Textarea from 'primevue/textarea';


import CascadeSelect from 'primevue/cascadeselect';



Vue.directive("badge", BadgeDirective);
Vue.component("Button", Button);
Vue.component("Toolbar", Toolbar);
Vue.component("ColorPicker", ColorPicker);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("MultiSelect", MultiSelect);
Vue.component("Toast", Toast);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("SplitButton", SplitButton);
Vue.component("Fieldset", Fieldset);
Vue.component("PickList", PickList);
Vue.component("InputSwitch", InputSwitch);
Vue.component("TabView", TabView);
Vue.component("TabPanel", TabPanel);
Vue.component("Dialog", Dialog);
Vue.component("ColumnGroup", ColumnGroup);
Vue.component("Row", Row);
Vue.component("Dropdown", Dropdown);
Vue.component("Checkbox", Checkbox);
Vue.component("Editor", Editor);
Vue.component("InputNumber", InputNumber);
Vue.component("OrderList", OrderList);
Vue.component("Chip", Chip);
Vue.component("InputText", InputText);
Vue.component("TieredMenu", TieredMenu);
Vue.component("OverlayPanel", OverlayPanel);
Vue.component("Calendar", Calendar);
Vue.component("SelectButton", SelectButton);
Vue.component("TreeTable", TreeTable);
Vue.component("Knob", Knob);
Vue.component("Card", Card);
Vue.component("Textarea", Textarea);



Vue.component("CascadeSelect", CascadeSelect);




import Calendar2 from "./plugins/calendar";
Vue.component("Calendar2", Calendar2);
